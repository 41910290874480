<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="withdraw"  label-width="100px">
      <el-form-item label="提现金额" :required="true">
        <el-input type="number"  v-model="withdraw.amount"  placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
    TipEnum,
    UrlEnum,
    WithdrawStatusEnum,
    request,
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "WithdrawSave",
  data() {
    return {
      loading: false,
      id:0,
      withdraw:{
        amount:''
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
  },
  methods: {
    submit: function () {
      if(this.withdraw.amount == ''){
        this.showMsgError("请输入提现金额");
        return;
      }
      this.loading = true;
      let url = UrlEnum.FINANCE_WITHDRAWS;
      let method = "post";
      this.$http({
        method: method,
        url: url,
        data: this.withdraw,
      })
          .then((res) => {
            this.loading = false;
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    }
  }
}
</script>

<style scoped>
</style>
